class confirmMessage {

    cancelText = "Abbruch"
    confirmText = "Ja"
    message = "Bist du sicher, dass du das Ausführen möchtest?"

    /**
     * 
     * @param {object} state Bitte das Store objekt übergeben 
     */
    constructor(store) {
        this.store = store
    }

    confirmFunction = () => {
        console.log("KEINE Aktion gewählt")
    }

    cancelFunction = () => {
        console.log("KEINE Aktion gewählt")
    }

    setCancelText(text) {
        if(text == null) {
            return
        }
        this.cancelText = text;
    }

    setConfirmText(text) {
        if(text == null) {
            return
        }
        this.confirmText = text;
    } 

    setMessage(text) {
        if(text == null) {
            return
        }
        this.message = text;
    }

    setConfirmFunction(functionElement) {
        if(functionElement == null) {
            return
        }
        this.confirmFunction = functionElement
    } 

    setCancelFunction(functionElement) {
        if(functionElement == null) {
            return
        }
        this.cancelFunction = functionElement
    } 

    /**
     * 
     * @param {string} message 
     * @param {function} confirmFunction 
     * @param {string} confirmText 
     * @param {string} cancelText 
     * @param {function} cancelFunction 
     */
    newConfirmMessage(message, confirmFunction, confirmText = null, cancelText = null, cancelFunction = null, runDirect = true) {
        this.setMessage(message);
        this.setConfirmFunction( confirmFunction );
        this.setConfirmText(confirmText);
        this.setCancelText(cancelText);
        this.setCancelFunction(cancelFunction);
        if(runDirect) {
            this.run();
        }
    }

    run() {
        this.store.dispatch('system/startNewConfirm', this);
    }

}

export default confirmMessage;