//const uuid = require('uuid');
import apiConnection from "@/assets/js/apiConnection"
import helper from "../js/helper";
import CartProduct from "./CartProduct";


class CartList {
    
    constructor() {
        this.name = ""
        this.id = null
        this.cartProducts = []
        this.community = ""
    }

    setName(name=undefined) {
        if(name != undefined) {
            this.name = name;
        }
        return this.name;
    }

    getName() {
        return this.name;
    }

    /**
     * 
     */
    setCommunity(community) {
        this.community = helper.getIri(community, '/api/communities/')
        return this.community
    }

    openTransportLayer(data) {
        this.name = data.name;
        this.id = data.id
        for(const product of data.cartProducts) {
            this.cartProducts.push(new CartProduct(product, this))
        }
        this.setCommunity(data.community)
    }

    getTransportLayer() {
        const data = {
            name: this.name,
            '@id': '/api/cart_lists/'+this.id,
            //cartProducts: [],
            community: this.community
        }
        return data
    }

    saveInDb() {
        let request;
        console.log(this.getTransportLayer())
        if(this.id === null) {
            request = apiConnection.post('/api/cart_lists', this.getTransportLayer());
        } else {
            request = apiConnection.patch('/api/cart_lists/'+this.id, this.getTransportLayer());
        }
        request.then((response) => {
            this.openTransportLayer(response.data)
        })
        return request
    }

    delete(store = null) {
        if(store != null) {
            store.dispatch('cart/deleteCart', this)
        }
        let request = apiConnection.delete('/api/cart_lists/'+this.id);
        return request;
    }
}


export default CartList