const flashMessage = {

    type:"success",
    message:"",
    aliveTime: 2000,
    /**
     * 
     * @param {string} type success, warning, danger, info 
     * @param {string} message Was soll angezeigt werden
     * @param {*} aliveTime Wie lange soll die Nachricht angezeigt werden in ms
     */
    newMessage( type, message, aliveTime = 2000) {
        this.type = type;
        this.message = message;
        this.aliveTime = aliveTime;
    },

    run(store) {
        store.dispatch('system/addFlashMessage', this);
    }

}

export default flashMessage;