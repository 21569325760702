import CartList from "@/assets/model/CartList"

import apiConnection from "@/assets/js/apiConnection"
const state = {
    cartLists: [],
    focusCartId: null
}


const mutations = {
    UPDATE_CART_LISTS(state, payload) {
        state.cartLists = []
        for(let cart of payload) {
            const cartObject = new CartList()
            cartObject.openTransportLayer(cart)
            state.cartLists.push(cartObject)
        }
    },
    UPDATE_CART(state, payload) {
        let index = state.cartLists.findIndex(obj => obj.id == payload.id)
        state.cartLists[index] = payload;
    },
    /**
     * 
     * @param {*} state 
     * @param {Integer} payload 
     */
    UPDATE_FOCUS_CART(state, payload) {
        state.focusCartId = payload
    },
    DELETE_CART(state, payload) {
        let index = state.cartLists.findIndex(obj => obj.id == payload.id);
        state.cartLists.splice(index,1)
    }
}

const actions = {
    getLists({commit}) {
        let request = apiConnection.get('/api/cart_lists');
        request.then((response) => {
            commit('UPDATE_CART_LISTS', response.data['hydra:member'])
        })
        return request;
    },
    /**
     * 
     * @param {*} param0 
     * @param {Integer} payload 
     */
    focusCart({commit}, payload = null) {
        if(payload == null) {
            payload = JSON.parse(window.localStorage.getItem('focusCartId'));
        } else {
            let jsonPayload = JSON.stringify(payload);
            window.localStorage.setItem('focusCartId', jsonPayload);
        }
        commit('UPDATE_FOCUS_CART', payload)
    },
    updateCart({commit}, payload) {
        commit('UPDATE_CART', payload)
        this.dispatch('cart/focusCart')
    },
    deleteCart({commit}, cart) {
        commit('DELETE_CART', cart)
    }

}

const getters = {
    getList: state => state.cartLists,
    getFocusCartId: state => state.focusCartId
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};