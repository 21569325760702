<template>
    <community-member-modal v-if="showMemberModal" :community="community" @setDisable="showMemberModal = false" />
    <div @click="openMenu" class="txBtn pointer txBtn-secondary text-dark">
        <i class="fa-solid fa-ellipsis fa-lg"></i>
    </div>
    <div @mouseleave="closeMenu" v-show="isVisable" :id="'itemNavi'+community.id"  class="itemNavi text-dark" >
        <div class="shadow">
            <div class="card-header bg-dark text-light ">
                {{community.name}}
            </div>
            <div @click="openEditMode" class="card-footer">
                Einstellung
            </div>       
            <div @click="openMembers" class="card-footer pointer">
                Mitglieder
            </div> 
            <div @click="leaveGroup" class="card-footer text-danger">
                Gemeinschaft verlassen
            </div>
            <div v-if="isAdmin(user.id)" @click="remove" class="card-footer text-danger">
                Löschen
            </div>
        </div>
    </div>
    <div @click="closeMenu" v-show="isVisable" class="bgForClickEvent">
    </div>
</template>

<script>
//import flashMessage from '@/assets/js/messageObjects/flashMessageObject';
import Community from '@/assets/model/Community';
import { mapGetters } from 'vuex';
import CommunityMemberModal from './CommunityMemberModal.vue'

export default {
    data() {
        return {
            parentDiv: null,
            isVisable: false,
            widthPos: 0,
            showMemberModal: false
        }
    },
    emits: ['openEditMode'],
    mounted() { 
        
    },
    methods: {
        openMenu(event) {
           
            this.isVisable = !this.isVisable;
            let menuElement = document.getElementById('itemNavi'+this.community.id);

            let windowWidth = document.documentElement.clientWidth;
            let leftPos = event.layerX
            let yStyle  = "top:"+ event.layerY+"px;";

            let stylePosition = "position: absolute;";
            if(windowWidth < (event.pageX+300)) {
                leftPos -=  300;
            }

            // Wenn es ein kleiner Monitor ist wird automatisch die Eingabe zentriert
            this.widthPos = windowWidth
            if(windowWidth < 400) {
               // let borderWidth = windowWidth-300;
                leftPos = 0//borderWidth/2;
                stylePosition = "position: fixed;"
                yStyle = "top:"  + 0 +   "px;width:"+windowWidth+"px;";
            }
    


            menuElement.style = stylePosition + yStyle+'left:'+ (leftPos)+'px'
        },
        openEditMode() {
            this.$emit('openEditMode');
        },
        openMembers() {
            this.showMemberModal = true;
        },
        closeMenu() {
            this.isVisable = false;
        },
        remove() {
            this.$store.dispatch('community/remove',this.community)
        },
        isAdmin(userId) {
            if(this.community.chiefs.find(obj => obj.id == userId)) {
                return true;
            }
            return false
        },
        leaveGroup() {
            this.community.removeMember(this.user);
            this.community.saveInDb().then(() => {
                this.$store.dispatch('community/getAll')
            }) 
            
        }
    },
    computed: {
        ...mapGetters({
            'user': 'user/getUser'
        })
    },
    props: {
        community: Community
    },
    components: {
        CommunityMemberModal
    }
}
</script>

<style lang="scss" scoped>
    .itemNavi {
        position: absolute;
        background-color: white;
        width: 300px;
        z-index: 200;
        border-radius: 3px;
    }

    .card-footer:hover {
        background: rgba($color: #000000, $alpha: 0.2);
        cursor: pointer;
    }

    .bgForClickEvent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
    }

</style>

