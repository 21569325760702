<template>
    <div v-if="cartId">
        <h1>{{cart.name}}</h1>
        <div class="input-group mb-3">
            <input @keyup.enter="newProduct" v-model="newProductName" class="form-control" placeholder="Neuen Artikel z.B. 40g Butter oder 2 Möhren" /> <button @click="newProduct" class="btn btn-success"><i class="fa-solid fa-plus"></i> Hinzufügen</button>
        </div>
        <div>
            <button  class="txBtn txBtn-primary" @click="moveProduct=!moveProduct"><i class="fa-solid fa-up-down-left-right"></i> Verschieben</button>
        </div>
        <div class="p-1">
            <div :key="product.id" v-for="(product) of getProducts">
                <cart-product-vue :cartProduct="product" :move="moveProduct" @changeSortIndex="changeSort" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CartProduct from '@/assets/model/CartProduct'
import CartProductVue from '@/components/cart/CartProduct.vue';

export default {
    name: "CartList",
    data() {
        return {
            newProductName: "",
            moveProduct: false
        }
    },
    beforeCreate() {
        this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart')
            })

    },
    created() {
        setTimeout(this.loopReload(), 2000);
    },
    computed: {
        ...mapGetters({
            cartId: 'cart/getFocusCartId',
            cartList: 'cart/getList'
        }),
        getProducts() {
            if(this.cartId != null) {
                let products = this.cartList.find(obj => obj.id == this.cartId).cartProducts
                //return products
                products = products.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                    });
                return products.sort((a, b) => a.isBought - b.isBought)
            }
            return []
        },
        cart() {
            if(this.cartId != null) {
                return this.cartList.find(obj => obj.id == this.cartId);
            }
            return null
        }
    },
    methods: {
        loopReload() {
            if(this.$route.name != "cartList") {
                return
            }
            this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart').then(() => {
                    setTimeout(() => {
                        this.loopReload();
                    }, 1500);
                }) 
            })

        },
        reload() {
            this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart')
            })
        },
        newProduct() {
            const cartProduct = new CartProduct();
            cartProduct.setName(this.newProductName)
            this.newProductName = "";
            cartProduct.setAmount(1);
            cartProduct.setCartList(this.cart)
            cartProduct.saveInDb().then(() => {
                this.reload()
            })
        },
        changeSort(usedId, onDropElement) {
            console.log(usedId)
            console.log(onDropElement)
        }
    },
    components: {
        CartProductVue
    }
}
</script>

<style scoped lang="scss">
</style>