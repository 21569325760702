<template>
    <div v-if="dialog.enabled" id="ConfirmDialog" class="fixed-top  border border-primary p-3 bg-dark">
        <div class="text-center">
            <div class="m-auto col-8">
                {{dialog.message}}
            </div>
            <div class="mt-2">
                <button @click="cancel" class="btn btn-outline-light me-2">{{dialog.cancelText}}</button>
                <button @click="callFunction" class="btn btn-outline-secondary">{{dialog.confirmText}}</button>
            </div>
        </div>
    </div>


    <!--- Fürs Handy ---->

<!--     <div v-if="dialog.enabled" id="ConfirmDialogMobile" class="d-md-none fixed-top p-1 bg-dark d-flex justify-content-around">
        <div class="mt-auto mb-auto">
            <span>
                {{dialog.message}}
            </span>
        </div>
        <div class="mt-auto mb-auto">
            <button @click="cancel" class="btn btn-outline-primary me-2">{{dialog.cancelText}}</button>
            <button @click="callFunction" class="btn btn-outline-secondary">{{dialog.confirmText}}</button>
        </div>
    </div> -->
</template>    

<script>
import { mapGetters } from 'vuex';
    
export default {
    data() {
        return {
        }
    },
    created() {
    },
    props: {
    },
    methods: {
        cancel() {
            this.$store.dispatch('system/disableConfirm', this.dialog);
        },
        callFunction() {
            this.dialog.confirmFunction()
            this.cancel();
        }
    },
    computed: {
        ...mapGetters({
            dialog: 'system/getConfirmDialog'
        })
    }
}
</script>

<style scoped lang="scss">
#ConfirmDialog {
    height: 180px;
        @media (min-width: 768px) { 
            height: 120px;
         }
}
</style>