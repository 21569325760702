const state = {
    autoFuelPriceImport: false
}

const mutations = {
    UPDATE_AUTO_FUEL_PRICE_IMPORT(state, payload) {
        state.autoFuelPriceImport = payload
    }
}

const actions = {
    load() {
        this.dispatch('localSetting/setAutoFuelPriceImport')
    },
    setAutoFuelPriceImport({commit}, payload = null) {
        if(payload != null) {
            window.localStorage.setItem('autoFuelPriceImport', JSON.stringify(payload))
        } else {
            payload = JSON.parse( window.localStorage.getItem('autoFuelPriceImport') )
        }
        commit('UPDATE_AUTO_FUEL_PRICE_IMPORT', payload)
    }
}

const getters = {
    getAutoFuelPriceImport: state => state.autoFuelPriceImport
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};