<template>
    <div class="p-3">
        <h1>Changelog</h1>
        <h3>XX.12.2022 - v1.5.0</h3>
        
        <h3>28.12.2022 - v1.4.2</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Einkaufslistennamen konnte nicht geändert werden</li>
            <li>Einkaufliste konnte nicht gelöscht werden</li>
        </ul>
        <hr>
        <h3>11.12.2022 - v1.4.1</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Einkaufsliste wird nicht nach Alphabet sortiert</li>
            <li>Einkaufsliste: Letzter Bearbeiter wird nicht richtig angezeigt wenn zwei User gleichzeitig in der Liste sind</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Einkaufsliste: Button für Erledigt geändert</li>
            <li>Einkaufsliste: Syncronisierungsgeschwindigkeit erhöht</li>
            <li>Text für Produkte im Einkaufswagen wieder durchgestrichen</li>
            <li>Einstellungsänderung Info, dass gespeichert wurde</li>
        </ul>
        <hr>
        <h3>11.12.2022 - v1.4.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Letzter Bearbeiter wird mit Namesinitalen direkt im Einkaufsprodukt angezeigt</li>
            <li>Auf Kick auf die Namensinitalen kann der komplette Name angezeigt werden und das letzte editier Datum wird angezeigt.</li>
            <li>Einlaufsliste wird, wenn geöffnet, im Hintergrund aktualisiert. Somit wird die Änderung eines anderen Nutzers automatisch aktualisiert (BETA)</li>
            <li>Favorisierte Treibstoffart</li>
            <li>Tankstellenpreis wird automatisch eingefügt werden (Über Einstellung aktivierbar)</li>
        </ul>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Falscher Text bei "Bestätigung zum Löschen" Warnung</li>
            <li>Entertaste zum Einloggen war nicht aktiv</li>
            <li>Entertaste zum Speichern neues Fahrzeug war nicht aktiv</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Wenn Produkt im Einkaufswagen wird hintergrund geschwärzt</li>
        </ul>
        <hr>
        <h3>07.12.2022 - v1.3.1</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Einkaufsliste kann nicht gelöscht werden</li>
            <li>Einkaufslistename kann nicht geändert werden</li>
            <li>Einkaufsliste werden nach dem erstellen nicht automatisch angezeigt</li>
            <li>Produktnamen kann nicht geändert werden</li>
        </ul>
        <hr>
        <h3>06.12.2022 - v1.3.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Gemeinschaftssystem</li>
            <li>Einladung für Gemeinschaft</li>
            <li>Fahrzeuge können nun auch von Gemeinschaftsmitgliedern gepflegt werden</li>
            <li>Einfache Einkaufsliste</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Profil ist nun über Einstellung aufrufbar</li>
        </ul>
        <hr>
        <h3>27.11.2022 - v1.2.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Baujahr kann nun einem Fahrzeug hinzugefügt werden</li>
            <li>Im Transaktionsformular wird der letzte Kilometerstand angezeigt</li>
            <li>Wenn Bild im Backend fehlt wird ein Placeholder stattdessen zurückgegeben</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Für die Rücklagenempfehlung wird nun das Baujahr + Alter berücksichtig.</li>
            <li>Backend und Frontend wurden getrennt und liegt nun auf unterschiedlichen Webspaces</li>
            <li>Neue Manifest und Serviceworker implementiert</li>
        </ul>
        <hr>
        <h3>14.11.2022 - v1.1.2</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Beim Aufrufen einer neuen Transaktion wird die Transaktion des letzten Tankvorgangs angezeigt.</li>
            <li>Aufklappen der Transaktionskarte schlägt fehlt beim klicken auf das Datum oder den Betrag.</li>
        </ul>
        <hr>
        <h3>13.11.2022 - v1.1.1</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Beim erstellen eines neuen Fahrzeugs kann dieses nicht bearbeitet werden.</li>
        </ul>
        <hr>
        <h3>13.11.2022 - v1.1.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Overlay Infobox für das Anzeigen wichtiger Informationen</li>
            <li>Filter und Suchfunktion bei Transaktionsliste</li>
            <li>Transaktionsbeschreibung</li>
            <li>Erstellung Changelog</li>
            <li>Hinweis und Weiterleitung wenn dem Fahrzeug noch keine Kraftsoffarten zugewiesen wurden</li>
            <li>Neue Kacheln auf der Startseite "Changelog" & "Einstellung"</li>
            <li>Analyseseite. Zugang über Dashboard => Kosten => Zur vollständigen Analyse</li>
            <li>Ein Hauptbild lässt sich vom Fahrzeug entfernen ohne ein neues Bild hochladen zu müssen</li>
            <li>Es wird ein Hinweis bzgl. zu großen Bildern angezeigt</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Das Fahrzeug wird nun automatisch gewechselt, sobald man ein Fahrzeug im Dashboard öffnet</li>
            <li>Transaktionskarte fährt auf und taucht nicht mehr plötzlich auf</li>
            <li>Im Tankformular kann nun mit Enter bzw. mit "," oder "." in das nächste Feld gesprungen werden</li>
            <li>Im Tankformular wird beim Import des Tankstellenpreises die Tankstellenadresse automatisch gespeichert</li>
            <li>Autopflege Button ist wurde klickbar und springt zum neuen Transaktionsformular</li>
            <li>Button "Neue Transaktion" in Transaktionsliste führt zum neuen Transaktionsformular</li>
            <li>Neues Fahrzeug Button wurde aus Fahrzeugliste entfernt. Neue Fahrzeuge können jetzt nur noch über die Kachel erstellt werden</li>
        </ul>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Absturz beim Import von Tankstellenpreisen</li>
            <li>Kraftstoffart wurde nicht automatisch vom Tankformular gespeichert</li>
        </ul>
        <h5 class="text-danger">Entfernt</h5>
        <ul>
            <li>Nichts</li>
        </ul>
        <hr>
    </div>

</template>

<script>
export default {
    name: "ChangeLog"
}
</script>

<style>
    h3 {
        text-align: center;
    }
</style>