import axios from "axios";
import qs from "qs"
qs


const apiConnection = {
    getApiServerUrl() {
        if(document.domain == "localhost") {
            return "https://localhost:8000"
        } else if (document.domain == "dev.grabasch.com") {
            return "https://apidev.grabasch.com"
        }  else if (document.domain == "one.grabasch.com") {
            return "https://api.grabasch.com"
        }  else if (document.domain == "172.16.1.13") {
            return "https://172.16.1.13:8000"
        } else {
            console.error("Kein passender API Server gefunden!")
        }
    },
    /**
     * 
     * @param {string} link Gebe den Link mit einem Vorangstellten / an
     * @returns AxiosStatic
     */
    get(link) {
        return axios.get(this.getApiServerUrl()+link,
        {

            withCredentials: true
        })
    },
    /**
     * 
     * @param {string} link Gebe den Link mit einem Vorangstellten / an
     * @param {object} data Die Daten als Object. Sie werden anschließend als Formular umgewandelt
     * @returns AxiosStatic
     */
    postForm(link, data) {
        const form = new FormData();
        for(let element of Object.keys(data)) {
            form.append(element,data[element])
        }
        return axios.post(this.getApiServerUrl()+link,form, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
    },
    /**
     * Für das erstellen neuer Einträge
     * @param {string} link  Gebe den Link mit einem Vorangstellten / an
     * @param {*} payload Die Daten als Object.
     * @returns 
     */
    post(link, payload) {
        return axios.post(this.getApiServerUrl()+link,payload, {
            headers: {
                'accept': 'application/ld+json',
                'Content-Type': 'application/json'
              }
        })
    },
    /**
     * Für das bearbeiten eines Eintrags
     * @param {string} link  Gebe den Link mit einem Vorangstellten / an
     * @param {*} payload Die Daten als Object.
     * @returns 
     */
     patch(link, data) {
        return axios.patch(this.getApiServerUrl()+link,data, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
                }
        })
    },

    /**
     * Für das erstellen neuer Einträge
     * @param {string} link  Gebe den Link mit einem Vorangstellten / an
     * @returns 
     */
    delete(link) {
        return axios.delete(this.getApiServerUrl()+link, {
            headers: {
                'accept': 'application/ld+json',
                'Content-Type': 'application/json'
                }
        })
    },
    /**
     * 
     * @param {*} files 
     * @param {*} moduleName 
     * @param {*} entryId 
     * @returns 
     */
    uploadFiles(files, moduleName = "default", entryId ="0") {
        var formData = new FormData();
        formData.append('moduleName', moduleName)
        formData.append('moduleEntryId', entryId)
        for( var i = 0; i < files.length; i++ ){
            let file = files[i];
            formData.append('files[' + i + ']', file);
          }
        let request = axios.post(this.getApiServerUrl()+"/apiController/file/uploadFiles", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        return request; 
    },

    getFileLink(fileId) {
        return this.getApiServerUrl()+"/apiController/file/getFile/"+fileId
    }
}

export default apiConnection;