<template>
    <div  @click="setDisable" class="dgModal">
        <div @click.stop class="card dgModal-content text-dark">
          <div class="card-header">
            <h3>{{community.name}} - Mitglieder</h3>
          </div>
          <div class="card-body">
            <div v-if="isAdmin(user.id)">
                <input @keydown.enter="addMember" class="form-control" v-model="email" placeholder="Emailadresse des neuen Mitglieds" />
                <button @click="addMember" class="btn btn-success float-end mt-1">Einladen</button>
                <div class="clearfix"></div>
            </div>
            <div v-if="community.invitations.length > 0 || community.invitationAttempts.length > 0">
                <h5>Einladungen</h5>
                <div :key="invitation.id" v-for="(invitation) of community.invitations">
                    <div class="card mt-1">
                        <div class="card-body">
                            <div class="mt-auto mb-auto">
                                <i v-if="isAdmin(invitation.id)" class=" fa-solid fa-feather"></i><i class="fa-regular fa-user"></i><span class="me-2"></span> {{invitation.email}}
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-outline-danger" @click="removeInvitation(invitation)"><i class="fa-solid fa-trash"></i> Entfernen</button> 
                            <button v-if="isAdmin(user.id)" @click="setAdmin(invitation)" class="float-end btn btn-secondary"><i class="fa-solid fa-feather"></i> Setze Häuptling</button>
                            <!--<button class="btn btn-outline-primary"><i class="fa-solid fa-envelope"></i> Erneut senden</button>-->
                        </div>
                    </div>
                </div>
                <div :key="invitation.id" v-for="(invitation) of community.invitationAttempts">
                    <div class="card mt-1">
                        <div class="card-body">
                            <div v-if="!invitation.isUserInDB" class="alert alert-danger">
                                Emailadresse konnte nicht in der Datenbank gefunden werden.
                            </div>
                            {{invitation.email}} 
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-outline-danger" @click="removeInvitationAttemt(invitation.email)"><i class="fa-solid fa-trash"></i> Entfernen</button>
                        </div>
                    </div>
                </div>
            </div>
            <h5>Mitglieder</h5>
            <div :key="member.id" v-for="(member) of community.members">
                <div class="card mt-1">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="mt-auto mb-auto">
                                <i v-if="isAdmin(member.id)" class=" fa-solid fa-feather"></i><i class="fa-regular fa-user"></i><span class="me-2"></span> {{member.email}}
                            </div>
                            <div @click="removeMember(member)" v-if="(user.id != member.id) && isAdmin(user.id)" class="text-danger pointer">
                                <i class="fa-solid fa-ban"></i>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" v-if="(user.id != member.id) && isAdmin(user.id)">
                        <button @click="removeAdmin(member)" v-if="isAdmin(member.id)" class="btn  btn btn-secondary"><i class="fa-regular fa-user"></i> Kein Häuptling</button>
                        <button @click="setAdmin(member)" v-if="!isAdmin(member.id)" class=" btn btn-secondary"><i class="fa-solid fa-feather"></i> Setze Häuptling</button>
                    </div>
                </div>
            </div>
          </div>
          <div class="card-footer">
            <button @click="setDisable" class="btn btn-outline-primary float-end">Schließen</button>
          </div>
        </div>
    </div>
</template>

<script>
import Community from '@/assets/model/Community';
import flashMessage from '@/assets/js/messageObjects/flashMessageObject';
import { mapGetters } from 'vuex';

export default {
data() {
    return {
        communityEdit: {},
        communicationError: null,
        email: ""
    }
},
created() {

},
methods: {
    setDisable() {
        this.$emit('setDisable');
    },
    addMember() {
        if(this.community.invitations.find(obj => obj.email == this.email)) {
            flashMessage.newMessage('danger', 'Die Emailadresse wurde bereits eingeladen');
            flashMessage.run(this.$store);
            return
        }
        this.community.addInvitationAttemt(this.email)
        this.community.saveInDb();
    },
    removeInvitationAttemt(email) {
        this.community.removeInvitationAttemt(email);
        this.community.saveInDb();
    },
    removeInvitation(user) {
        this.community.removeInvitation(user);
        this.community.saveInDb();
    },
    removeMember(user) {
        console.log(user)
        this.community.removeMember(user)
        this.community.saveInDb();
    },
    setAdmin(user) {
        this.community.addChief(user);
        this.community.saveInDb();
    },
    removeAdmin(user) {
        this.community.removeChief(user);
        this.community.saveInDb();
    },
    isMember(userId) {
        if(this.community.members.find(obj => obj.id == userId)) {
            return true;
        }
        return false;
    },
    isAdmin(userId) {
        if(this.community.chiefs.find(obj => obj.id == userId)) {
            return true;
        }
        return false
    }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    props: {
        community: Community
    },
    components: {
        
    }
}
</script>