<template>
    <community-new-item-modal v-if="openNewItemModal" @setDisable="openNewItemModal = false" />
    <h1>Gemeinschaft</h1>

    <community-invitation-message />

    <div @click="openNewItemModal = true" class="txBtn txBtn-primary float-end">Neue Gemeinschaft gründen</div>

    <div class="clearfix"></div>
    <div class="mt-3">
        <div class="mt-2" :key="index" v-for="(community, index) of communityItems">
            <community-item-card :community="community" />
        </div>
        
    </div>

</template>

<script>
import CommunityInvitationMessage from "@/components/community/CommunityInvitationMessage.vue";
import CommunityItemCard from "@/components/community/CommunityItemCard.vue"
import CommunityNewItemModal from "@/components/community/CommunityNewItemModal.vue";
import { mapGetters } from "vuex";


export default {
    data() {
        return {
            openNewItemModal: false
        }
    },
    beforeCreate() {
        this.$store.dispatch('community/getAll');
        this.$store.dispatch('cart/getLists')
    },
    computed: {
        ...mapGetters({
            'communityItems': 'community/getAll'
        })
    },
    components: {
    CommunityItemCard,
    CommunityNewItemModal,
    CommunityInvitationMessage
}
}

</script>

<style lang="scss" scoped>
</style>