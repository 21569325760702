<template>
    <div id="moveableObject" ref="moveableObject">
        <div v-if="selectedObject.cartProduct" @mouseup="stopMoving">
            <cart-product :cartProduct="selectedObject.cartProduct" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CartProduct from '../cart/CartProduct.vue';
    export default {
        data() {
            return {
                
            }
        },
        mounted() {
            document.addEventListener('mousemove', this.changePosition)
        },
        computed: {
            ...mapGetters({
                selectedObject: 'system/getMovingObject',
                mousePosition: 'system/getMousePosition'
            })
        },
        methods: {
            changePosition() {
                this.$nextTick(() => {
                    this.$refs.moveableObject.style.top = (this.mousePosition.y - (this.$refs.moveableObject.clientHeight/2))+"px";
                    this.$refs.moveableObject.style.left = (this.mousePosition.x - (this.$refs.moveableObject.clientWidth/2))+"px";
                })

            },
            stopMoving() {
                this.$store.dispatch('system/setMovingObject', {})
            }
        },
        components: {
            CartProduct
        }
    }
</script>

<style scoped>
    #moveableObject {
        position: fixed;
        top: 302px;
        left: 30px;
    }

</style>